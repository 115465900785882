import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { ForgotPassSetPassEnd, ResetForgotPassGetOTPEnd } from '../../constants/api.constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Toast from '../../utils/Toast/Toast'
import "./ForgotPassReset.scss"
const ForgotPassReset = () => {
  const location = useLocation();
  const [spin, setSpin] = useState(false)
  const [email, setEmail] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [OTP, setOTP] = useState('')
  const [pass, setPass] = useState('')
  const Email = location.state?.email || 'No email provided';

  let history = useHistory()
  const HttpStatus = {
    OK: 200,

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();  // Add this line
    setSpin(true)

    if (email === '') {
      Toast('err', 'email cannot e empty')
      setSpin(false)
      return
    }

    try {
      const response = await axios.put(
        ForgotPassSetPassEnd,
        {
          email: email,
          otp: OTP,
          password: pass,
        },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === 200) {
        Toast('success', 'New Password Set!')
        setSpin(false)
        setEmail('')
        history.push('/login')
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      setSpin(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const handleSubmitResend = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        ResetForgotPassGetOTPEnd,
        {
          email: Email,
        },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === HttpStatus.OK) {
        Toast('success', 'OTP has been sent to your email!')
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
    setButtonDisabled(true);
    const oldTime = new Date().getTime();
    localStorage.setItem('cdTime', oldTime);

    // Enable the button after 30 seconds

  }
  useEffect(() => {
    let timer;

    const oldTImes = localStorage.getItem('cdTime');
    const oldTIme = Math.ceil(oldTImes/1000);
    const newTimes = new Date().getTime();
    const newTime = Math.ceil(newTimes/1000);;
    const currenTime = Math.abs(newTime -oldTIme) ;
    console.log("usefff", (newTime - oldTIme))
    if (currenTime <= 30) {
      setButtonDisabled(true);
      setCountdown(parseInt(currenTime));

    }

    if (isButtonDisabled) {
      // Update countdown every second
      timer = setInterval(() => {
        const newTimes = new Date().getTime();
        const newTime = Math.ceil(newTimes/1000);;

        const newCd = Math.abs(newTime -oldTIme) ;
        console.log("cdddd", newCd, typeof newCd)
        const newCountdown = newCd >= 30 ? 0 : newCd;
        setCountdown(newCountdown)
       
      }, 1000);
    }

    // Cleanup interval on component unmount or when countdown reaches 0
    return () => clearInterval(timer);
  }, [isButtonDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setButtonDisabled(false);
    }
  }, [countdown]);


  return (
    <div className='login-card  '>
      <h2 className='text-center fw-bold'>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className='plain-input mt-3 '>
          <label for=''>Email</label>
          <br />
          <input
            type='text'
            placeholder='enter your email'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className='plain-input mt-3 '>
          <label for=''>OTP</label>
          <br />
          <input
            type='text'
            placeholder='enter your OTP'
            onChange={(e) => setOTP(e.target.value)}
            value={OTP}
          />
        </div>
        <div className='plain-input mt-3'>
          <label htmlFor='password'>New Password</label>
          <br />
          <div style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='enter your password'
              onChange={(e) => setPass(e.target.value)}
              value={pass}
            />
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
              className='eye-btn'
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
        </div>
        <button
          className='primary-btn w-100 my-4 d-flex justify-content-center align-items-center'
          onClick={handleSubmitResend}
          type='button'
          disabled={isButtonDisabled}
          style={{ backgroundColor: isButtonDisabled && '#f8f8f8', color: isButtonDisabled && "black" }}
        >
          {isButtonDisabled ? `Resend OTP in ${Math.abs(countdown -30)} seconds` : 'Resend OTP'}

        </button>

        <button
          className='primary-btn w-100 my-4 d-flex justify-content-center align-items-center'
          type='submit'
        >
          Reset Password{' '}
          <Spinner
            animation='border'
            size='sm'
            className={spin ? 'd-block ms-2' : 'd-none ms-2'}
          />
        </button>

      </form >
    </div >
  )
}

export default ForgotPassReset
