import React from 'react'
import './NavigationBar.scss'
import accIcon from '../../assets/icons/account_circle.svg'
import { useAuth } from '../../Providers/AuthProvider'
import '../../index.scss'
import { Link } from 'react-router-dom'

const NavigationBar = () => {
  const auth = useAuth()

  const logout = () => {
    auth.setUser(null)
    localStorage.removeItem('company_token')
    localStorage.removeItem('user_role')
  }
  return (
    <section className='nav-section'>
      <nav className='custom-navbar d-flex justify-content-between align-items-center'>
        <Link to={localStorage.getItem("default_path")} style={{ textDecoration: 'none' }}>
          <h2 style={{ fontWeight: 'bold' }}>MenuBoard</h2>
        </Link>
        {
          localStorage.getItem("user_role") === "ADMIN" && (
            <Link to='/profile'>
              <img src={accIcon} alt='' />
            </Link>
          )
        }
        {
          localStorage.getItem("user_role") === "PRODUCTADMIN" && (
            <button className='danger-btn-light my-3  px-5' onClick={() => logout()}>
              Log Out
            </button>
          )
        }

      </nav>
    </section>
  )
}

export default NavigationBar
