const ROUTENAME = {

    HOME: '/',
    ADMINMANAGEMENT: '/admin-management',
    COMPANYFRONTMANAGEMENT: '/companyfront-management',
    STOREFRONTMANAGEMENT: '/storefront-management',
    ADDMANAGEMENT: '/ad-management/:folderId?',
    STOREFRONT: '/storefront/:id',
    COMPANYFRONT: '/companyfront/:id',
    TICKET: '/ticket/:id',
    GLOBALSETTINGS: '/global-settings',
    LOGIN: '/login',
    LOGINPRODUCT: '/product-login',
    PROFILE: '/profile',
    THEMEMANAGEMENT: '/theme-management',
    FORGOTPASSRESET: '/forgot-pass-reset',
    STOREHOME: '/store',
    ProductList: '/product-list/:id',
    TICKETINGPANEL: '/ticketing-panel',
    NOTFOUND: '*',

}

export default ROUTENAME;