import React from 'react'
import { NavLink } from 'react-router-dom'
import './SideBar.scss'
import icon2 from '../../assets/icons/icon2.svg'
import icon from '../../assets/icons/icon.svg'

const CompanySideBar = () => {
    return (
        <div>
            <div className=''>
                <ul className='list-group sticky-top  '>
                    <NavLink as='li' className='list-group-item' to='/' exact>
                        <img src={icon} alt="icons" />


                        <span className='ps-3'> DashBoard</span>
                    </NavLink>
                    <NavLink
                        as='li'
                        className='list-group-item'
                        to='/companyfront-management'
                        exact
                    >
                        <img src={icon2} alt="" />

                        <span className='ps-3'>Companyfront Management</span>
                    </NavLink>
                </ul>
            </div>
        </div>
    )
}

export default CompanySideBar
