import React, { useState,useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import Toast from '../../utils/Toast/Toast'
import Frame from '../../assets/images/Frame.png'
import ScrnImg from '../../assets/images/screen-img.png'
import '../AdCards/AdCards.scss'
import axios from 'axios'
import { postAdPushURL,GetStoreAdLog } from '../../constants/api.constants'
import {Spinner} from 'react-bootstrap'
import { AdReportModal } from '../Modals/AdReportModal'

const AdCard = ({ ad, index, screens,storeId }) => {
  const [pushAddSPinner,setPushAddSPinner] = useState(false);
  const [show, setShow] = useState(false)
  const [showImgModal, setShowImgModal] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [adEdit, setAdEdit] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [pushingAd, setPushingAd] = useState('');
  const [showAdReportModal, setShowadReportModal] = useState(false)
  const handleAdModalClose = ()=> setShowadReportModal(false);
  const showAdModal = ()=> setShowadReportModal(true);
  const [adReport,setAdReport] = useState(
    {
      "_id": "647b4f406e0501ded38ec3f0",
      "total_duration": 45,
      "total_count": 9
    }
  );
  useEffect(() => {
    if(localStorage.getItem('menu_token')){
      getAdLog()
    }
    
  }, [])
  
  const getAdLog = async()=>{
    try{
      const url = GetStoreAdLog + `${storeId}/ad/${ad.ad_id._id}`;
      const res = await axios.get(url,{
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      });
      if(res.status === 200){
         setAdReport(res.data.data)
      }
    }catch(err){
      Toast('err', err.response?.data?.msg || 'Something went wrong! Try again later.')
    }
    
  }
  const pushAds = async (e) => {
      setPushingAd(e)
      setPushAddSPinner(true);
      try{
        const res = await axios.post(postAdPushURL,{
          "ad_id": ad.ad_id._id,
          "screen_id": e
        },{
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        });
        if(res.status === 200){
          Toast('success', 'Ad pushed successfully')
          setPushAddSPinner(false);
          setPushingAd('')
        }
      } catch (error) {
        Toast('err', error.response?.data?.msg || 'Something went wrong! Try again later.')
        setPushAddSPinner(false);
        setPushingAd('')
      }
      
      
  };
  return (
    <div className='my-3  ad-card mx-1'>
      <div 
        className=' d-flex justify-content-start align-items-center '
          
      >
        <h4 className='me-2'>{index}</h4>
        {ad?.ad_id?.type === 'photo' ? (
          <div
            className='mx-3  preview-bg '
            style={{ backgroundImage: ` url("${ad?.ad_id?.link || ScrnImg}")`, 
            width:'20%'
          }}
          >
            <img
              src={Frame}
              alt=''
              className=''

              onClick={() => setShowImgModal(true)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        ) : (
          <div className='mx-3'
                style={{width:'20%'}}
          >
            <ReactPlayer
              url={ad?.ad_id?.link}
              width='100%'
              height='100px'
              controls={true}
            />
          </div>
        )}
        <div className='pe-5'
          style={{width:'20%'}}
        >
          <h6 className='fw-bold'> {ad?.ad_id?.name} </h6>
          <h6 style={{ fontWeight: 'normal' }}>{ad?.ad_id?.description}</h6>
          <h6 style={{ fontWeight: 'normal' }}>Ad Type: {ad?.ad_id?.type}</h6>
        </div>
        {
          localStorage.getItem('menu_token') && (
            <>
              <div className='pe-5 d-flex justify-content-between'
          style={{width:'40%'}}
        >
          <div className='d-flex flex-row'>
            <div>
              <h6 className='fw-bold'> Played : &nbsp;</h6>
              <h6 className='fw-bold'> Count&nbsp; : </h6>
            </div>
            <div>
              <h6 className='fw-normal'> {adReport?.total_duration} sec</h6>
              <h6 className='fw-normal'> 
                {adReport?.total_count} &nbsp;
                {adReport?.total_count>1?('times'):('time')}
              </h6>
            </div>
          </div>
          <div style={{marginBlock:'auto'}}>
            <button 
              className='reportButton'
              onClick={() => setShowadReportModal(true)}
            >
              Full Report</button>
          </div>
          
          
        </div>
        <div className='pe-5'
          style={{width:'20%'}}
        >
          {/* <div className='plain-dropdown  mt-2'>
            <label for=''> 
              Push Ad
              {pushAddSPinner && (<Spinner className='ms-2' animation='border' size='sm' />)}
            </label>
            <select onChange={(e) => pushAds(e.target.value)}>
            {screens?.map((screen, idx) => (
              <option value={screen._id}>{screen.screen_name}</option>  
            ))}              
            </select>
          </div>  */}
          
          <div class="dropdown">
            <button class="dropdown-toggle">Push Ad</button>
            <div class="dropdown-menu">
              {screens?.map((screen, idx) => (
                <a onClick={() => pushAds(screen?._id)}>
                  {screen?.screen_name}
                  {pushAddSPinner && pushingAd === screen?._id && (<Spinner className='ms-2' animation='border' size='sm' />)}
                </a>  
              ))}            
            </div>
          </div>

        </div>
            </>
          )
        }
        
        
      </div>
      <Modal
        show={showImgModal}
        onHide={() => setShowImgModal(false)}
        size='xl'
        centered
      >
        <img src={ad?.ad_id?.link || ScrnImg} alt='' />
      </Modal>
      {
        localStorage.getItem('menu_token') && (
          <AdReportModal
            show={showAdReportModal}
            handleClose={handleAdModalClose}
            storeId = {storeId}
            adId = {ad?.ad_id?._id}
        />
        )
      }
      
    </div>

    // <div className='my-3 d-flex justify-content-between align-items-start screen-section'>
    //   <div className=' d-flex justify-content-between align-items-center '>
    //     <h5 className='me-2'>{index}. </h5>
    //     <img
    //       src={screenImg}
    //       alt=''
    //       className='me-3'
    //       width='240px'
    //       height='100px'
    //     />
    //     <img
    //       src={Frame}
    //       alt=''
    //       style={{
    //         position: 'relative',
    //         right: '10rem',
    //         top: '2.2rem',
    //         cursor: 'pointer',
    //       }}
    //       // onClick={() => handleShow(true)}
    //     />
    //     <div className='ms-3 screen-right'>
    //       <h6 className='fw-bold'>{ad?.ad_id?.name}</h6>
    //       <h6 style={{ color: 'dimgray' }}>{ad?.ad_id?.description}</h6>
    //     </div>
    //   </div>

    //   <Modal show={show} onHide={handleClose} size='xl' centered>
    //     <img src={AddScreen} alt='' />
    //   </Modal>
    //   <EditAdModal show={adEdit} handleClose={() => setAdEdit()} />
    // </div>
  )
}

export default AdCard
