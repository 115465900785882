import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Alert, Dropdown, Modal, Spinner, Table } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import { useHistory } from 'react-router-dom'
import plus from '../../assets/icons/plus.svg'
import threedot from '../../assets/icons/threedot.svg'
import DashBoard from '../../components/DashBoard/DashBoard'
import { AdminDetailsModal } from '../../components/Modals/AdminDetailsModal'
import { NewAdminModal } from '../../components/Modals/NewAdminModal'
import { ResetOtherPassModal } from '../../components/Modals/ResetOtherPassModal'
import {
  AdminEdit,
  ChangeRole,
  GetAdminApi,
  GetAdminProfileUrl,
  UserDeleteEnd,
} from '../../constants/api.constants'
import Toast from '../../utils/Toast/Toast'
import './AdminManagement.scss'
import EditOthersProfileModal from '../../components/Modals/EditOthersProfileModal/EditOthersProfileModal'

const AdminManagement = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  let history = useHistory()
  const [allAdmins, setAllAdmins] = useState([])
  const [allmanager, setAllManager] = useState([])
  const [alltickingStaff, setAlltickingStaff] = useState([])

  const [searchKey, setSearchKey] = useState('')
  const [page, setPage] = useState(1)
  const [spinner, setSpinner] = useState(true)
  const [loading, setLoading] = useState(true);
  const [totalDoc, setTotalDoc] = useState(0)
  const [role, setRole] = useState('')
  const [detailsDataModal, setDetailsDataModal] = useState(false)
  const closeDetails = () => setDetailsDataModal(false)
  const [detailsData, setDetailsData] = useState({})
  const [userData, setUserData] = useState({})


  const Roles = {
    TICKETING_STAFF: 'ticketingstaff',
    MANAGER: 'manager',
    ADMIN: 'admin',
    SUPER_ADMIN: "super_admin"
  };

  useEffect(() => {
    GetUser()
  }, [])
  useEffect(() => {
    if (userData?.effective_role === Roles.TICKETING_STAFF) {
      history.push("/")
    }
    if (userData?.effective_role) {
      loadAllAdmin()
      setLoading(false)
    }
  }, [page, searchKey, role, userData])

  const loadAllAdmin = async () => {
    setSpinner(true)

    let url = GetAdminApi + `?page=${page}`
    if (searchKey.length > 0) {
      url += `&filter=${searchKey}`
      setPage(1)
    }
    // if 
    if (userData?.effective_role === 'manager') {
      url += '&role=ticketingstaff'
      setPage(1)
    }
    // else if (userData?.effective_role === "admin") {
    //   // url += '&role=manager'
    //   url += `&role=${role}`
    //   setPage(1)
    // }
    else if (role.length > 0) {
      url += `&role=${role}`
      setPage(1)
    }

    try {
      const response = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setAllAdmins(response.data.data)
        setTotalDoc(response.data.total_document)
        setSpinner(false)
      } else
        throw new Error(
          response?.data?.msg || 'Cant load admins data, try again later'
        )
    } catch (error) {
      setSpinner(false)

      Toast(
        'err',
        error.response?.data?.msg || 'Cant load admins data, try again later'
      )
    }

    // useEffect(() => {

    // }, [])

  }

  const GetUser = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setUserData(response?.data?.data)
      } else throw new Error(response?.data?.msg)
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }
  }

  const [individualData, setIndividualData] = useState({})
  const [othersProfileModal, setOthersProfileModal] = useState(false)
  const [userRole, setUserRole] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [editSpinner, setEditSpinner] = useState(false)
  const [deleteSpinner, setDeleteSpinner] = useState(false)

  const handleEditOthersModal = (admin) => {
    setIndividualData({
      _id: admin._id,
      name: admin.name,
      phone: admin.phone,
    })
    setUserEmail(admin.email)
    setUserRole({
      id: admin._id,
      role: admin.effective_role,
    })
    setOthersProfileModal(true)
  }

  const updateRole = async () => {
    try {
      const response = await axios.put(ChangeRole, userRole, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (response.status === 200) {
        // Toast('success', 'Role Updated!')
        return true
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
      return error
    }
  }
  const roleMap = {
    'super_admin': 'Super Admin',
    'admin': 'Admin',
    'manager': 'Manager',
    'ticketingstaff': "Ticketing Staff"
    // Add more roles and their corresponding labels here
  };


  const handleSubmit = async () => {
    setEditSpinner(true)

    if (individualData.name === '') {
      Toast('err', 'Please enter your name')
      setEditSpinner(false)

      return
    }
    if (individualData.phone === '') {
      Toast('err', 'Please enter your phone')
      setEditSpinner(false)
      return
    }
    updateRole()
    try {
      const response = await axios.put(AdminEdit, individualData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        Toast('success', 'Admin updated!')
        setEditSpinner(false)
        loadAllAdmin()
        setOthersProfileModal(false)
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      setEditSpinner(false)
      setOthersProfileModal(false)
      loadAllAdmin()
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

  const handleDelete = async (id) => {
    setDeleteSpinner(true)

    try {
      const response = await axios.delete(UserDeleteEnd + `?_id=${id}`, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        const data = response?.data?.data?.data?.effective_role;
        Toast("success", `${data} deleted!`); // Added a missing comma here
        handleClose();
        setDeleteSpinner(false);
        loadAllAdmin();
        setOthersProfileModal(false);
      }
       else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      handleClose()
      setDeleteSpinner(false)
      setOthersProfileModal(false)
      loadAllAdmin()
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

  const [showResetPassOther, setShowResetPassOther] = useState(false)
  const [changePassData, setChangePassData] = useState('')
  // pagination start

  let items = []
  let totalPage = 0
  if (totalDoc < 10) totalPage = 1
  else totalPage = Math.ceil(totalDoc / 10)
  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number == page}>
        {number}
      </Pagination.Item>
    )
  }
  // pagination end

  return (
    <div className='row py-3'>
      <div className='col-3'>
        <DashBoard />
      </div>
      <div className='col-9'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='fw-bold'>Admin Management</h3>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={handleShow}
          >
            <img className='me-3' src={plus} alt='' /> New User
          </button>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <div className='custom-input me-2'>
            <label for=''>Search Admin</label>
            <br />
            <input
              type='text'
              placeholder='search by name, email etc.'
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
          <div className='custom-dropdown ms-2'>
            <label for=''>Show</label>
            <select onChange={(e) => setRole(e.target.value)}>
              {userData?.effective_role === "super_admin" && (
                <>
                  <option value=''>All Roles</option>
                  <option value='admin'>Admin </option>
                  <option value='manager'>Manager</option>
                  <option value='super_admin'>Super Admin </option>
                  <option value='ticketingstaff'> Ticketing-Staff </option>

                </>
              )}
              {userData?.effective_role === "admin" && (
                <>
                  <option value=''>All Roles</option>
                  <option value='manager'>Manager</option>
                  <option value='ticketingstaff'> ticktingstaff </option>
                </>
              )}
              {userData?.effective_role === "manager" && (
                <>
                  <option value='ticketingstaff'>ticketingstaff</option>

                </>
              )}
            </select>
          </div>
        </div>
        {spinner && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Spinner animation='border' style={{ color: '#558f55' }} />
          </div>
        )}
        {!spinner && allAdmins.length > 0 ? (
          <Table
            striped
            bordered
            hover
            responsive
            borderless={true}
            className='my-5 text-start'
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                {/* <th>password</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {( userData.effective_role === Roles.MANAGER) && (allAdmins.filter((data) => data.effective_role == Roles.TICKETING_STAFF).map((admin, idx) => (
                <tr key={idx}>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    {admin.name}
                  </td>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {admin.email}
                  </td>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {admin.phone}
                  </td>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {/* {admin.role.map((r, idx) => (idx !== 0 ? ', ' + r : r))} */}
                    {/* {admin?.effective_role === 'super_admin'
                      ? 'Super Admin'
                      : admin?.effective_role === 'admin'
                        ? 'Admin'
                        : admin?.effective_role === 'manager'
                          ? 'Manager'
                          : 'Ticketing Staff'} */}

                    {roleMap[admin?.effective_role]}
                  </td>

                  <td className=' text-center'>
                    <Dropdown drop='start' style={{ cursor: 'pointer' }}>
                      <Dropdown.Toggle
                        variant='transparent'
                        id='dropdown-basic'
                      >
                        <img src={threedot} alt='' className='' />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='mt-4'>
                        <Dropdown.Item
                          onClick={() => {
                            handleEditOthersModal(admin)
                          }}
                        >
                          Edit profile
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowResetPassOther(true)
                            setChangePassData(admin)
                          }}
                        >
                          reset password
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <div class="dropdown"  >
                      <button class="btn" type="button"  data-bs-toggle="dropdown" aria-expanded="false"  style={{ zIndex: 2 }}>
                        <img src={threedot} alt='grg' className='' />
                      </button>
                      <ul class="dropdown-menu"   style={{position:'absolute',right:"10px"}}>
                        <li><a class="dropdown-item" onClick={() => {
                          handleEditOthersModal(admin)
                        }}>Edit</a></li>
                        <li><a class="dropdown-item" onClick={() => {
                          setShowResetPassOther(true)
                          setChangePassData(admin)
                        }}>Reset Password</a></li>
                      </ul>
                    </div> */}

                  </td>
                </tr>)
              ))}

              {(userData.effective_role === Roles.ADMIN) &&
                allAdmins.filter((data) => data.effective_role === Roles.MANAGER || data.effective_role === Roles.TICKETING_STAFF).map((admin, idx) => (
                  <tr key={idx}>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      {admin.name}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.email}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.phone}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {/* {admin.role.map((r, idx) => (idx !== 0 ? ', ' + r : r))} */}
                      {/* {determineUserRole(admin?.effective_role) === Roles.SUPER_ADMIN
                        ? Roles.SUPER_ADMIN
                        : determineUserRole(admin?.effective_role)=== Roles.ADMIN
                          ? Roles.ADMIN
                          : determineUserRole(admin?.effective_role) === Roles.MANAGER
                            ? Roles.MANAGER
                            : Roles.TICKETING_STAFF} */}
                      {roleMap[admin?.effective_role]}
                    </td>

                    <td className=' text-center'>
                      <Dropdown drop='start' style={{ cursor: 'pointer' }}>
                        <Dropdown.Toggle
                          variant='transparent'
                          id='dropdown-basic'
                        >
                          <img src={threedot} alt='' className='' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='mt-4'>
                          <Dropdown.Item
                            onClick={() => {
                              handleEditOthersModal(admin)
                            }}
                          >
                            Edit profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setShowResetPassOther(true)
                              setChangePassData(admin)
                            }}
                          >
                            reset password
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <div class="dropdown"  >
                      <button class="btn" type="button"  data-bs-toggle="dropdown" aria-expanded="false"  style={{ zIndex: 2 }}>
                        <img src={threedot} alt='grg' className='' />
                      </button>
                      <ul class="dropdown-menu"   style={{position:'absolute',right:"10px"}}>
                        <li><a class="dropdown-item" onClick={() => {
                          handleEditOthersModal(admin)
                        }}>Edit</a></li>
                        <li><a class="dropdown-item" onClick={() => {
                          setShowResetPassOther(true)
                          setChangePassData(admin)
                        }}>Reset Password</a></li>
                      </ul>
                    </div> */}

                    </td>
                  </tr>
                ))}

              {(userData.effective_role === Roles.SUPER_ADMIN) &&
                allAdmins.map((admin, idx) => (
                  <tr key={idx}>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      {admin.name}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.email}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.phone}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {/* {admin.role.map((r, idx) => (idx !== 0 ? ', ' + r : r))} */}
                      {/* {determineUserRole(admin?.effective_role) === Roles.SUPER_ADMIN
                        ? Roles.SUPER_ADMIN
                        : determineUserRole(admin?.effective_role)=== Roles.ADMIN
                          ? Roles.ADMIN
                          : determineUserRole(admin?.effective_role) === Roles.MANAGER
                            ? Roles.MANAGER
                            : Roles.TICKETING_STAFF} */}
                      {roleMap[admin?.effective_role]}
                    </td>

                    <td className=' text-center'>
                      <Dropdown drop='start' style={{ cursor: 'pointer' }}>
                        <Dropdown.Toggle
                          variant='transparent'
                          id='dropdown-basic'
                        >
                          <img src={threedot} alt='' className='' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='mt-4'>
                          <Dropdown.Item
                            onClick={() => {
                              handleEditOthersModal(admin)
                            }}
                          >
                            Edit profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setShowResetPassOther(true)
                              setChangePassData(admin)
                            }}
                          >
                            reset password
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <div class="dropdown"  >
                      <button class="btn" type="button"  data-bs-toggle="dropdown" aria-expanded="false"  style={{ zIndex: 2 }}>
                        <img src={threedot} alt='grg' className='' />
                      </button>
                      <ul class="dropdown-menu"   style={{position:'absolute',right:"10px"}}>
                        <li><a class="dropdown-item" onClick={() => {
                          handleEditOthersModal(admin)
                        }}>Edit</a></li>
                        <li><a class="dropdown-item" onClick={() => {
                          setShowResetPassOther(true)
                          setChangePassData(admin)
                        }}>Reset Password</a></li>
                      </ul>
                    </div> */}

                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          !spinner && (
            <h3 className='text-secondary my-5 text-center'>No Admin Found!</h3>
          )
        )}
        {!spinner && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Pagination
              onClick={(e) => {
                setPage(e.target.innerText)
              }}
            >
              {items}
            </Pagination>
          </div>
        )}
      </div>

      <NewAdminModal
        show={show}
        handleClose={handleClose}
        loadAllAdmin={() => loadAllAdmin()}
        userData={userData}
      />
      <AdminDetailsModal
        show={detailsDataModal}
        handleClose={closeDetails}
        data={detailsData}
      />
      <EditOthersProfileModal
        show={othersProfileModal}
        handleClose={() => setOthersProfileModal()}
        data={individualData}
        loadAdmin={() => loadAllAdmin()}
      />
      <ResetOtherPassModal
        show={showResetPassOther}
        handleClose={() => setShowResetPassOther()}
        data={changePassData}
      />
      <Modal
        show={othersProfileModal}
        onHide={() => setOthersProfileModal(false)}
        size='md'
      >
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <div className='plain-input my-3'>
              <label for=''>User Name</label>
              <br />
              <input
                type='text'
                placeholder='Search something'
                value={individualData.name}
                onChange={(e) =>
                  setIndividualData({ ...individualData, name: e.target.value })
                }
              />
            </div>

            <div className='plain-input my-3'>
              <label for=''>Phone</label>
              <br />
              <input
                type='text'
                placeholder='Search something'
                value={individualData.phone}
                onChange={(e) =>
                  setIndividualData({
                    ...individualData,
                    phone: e.target.value,
                  })
                }
              />
            </div>

            <div className='plain-input my-3'>
              <label for=''>Email</label>
              <br />
              <input
                type='text'
                placeholder='Search something'
                value={userEmail}
                disabled
              />
            </div>

            <div className='plain-dropdown '>
              <label for=''>Role </label>
              <select
                onChange={(e) =>
                  setUserRole({ ...userRole, role: e.target.value })
                }
              >
                <option
                  value='ticketingstaff'
                  selected={userRole.role === Roles.TICKETING_STAFF ? true : false}
                >
                  Ticketing-Staff
                </option>
                {userData?.effective_role === Roles.MANAGER ||(
                  <option
                    value='manager'
                    selected={userRole.role === Roles.MANAGER ? true : false}
                  >
                    Manager
                  </option>
                )}
               
                {userData?.effective_role === Roles.SUPER_ADMIN && (
                  <option
                    value='admin'
                    selected={userRole.role === Roles.ADMIN ? true : false}
                  >
                    {' '}
                    Admin
                  </option>
                )}

              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='danger-btn-light d-flex justify-content-center align-items-center'
            onClick={() => handleDelete(userRole.id)}
          >
            Delete
            <Spinner
              className={deleteSpinner ? 'd-block ms-2' : 'd-none ms-2'}
              animation='border'
              size='sm'
            />
          </button>
          <button
            className='primary-btn-light'
            onClick={() => setOthersProfileModal(false)}
          >
            Close
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center'
            onClick={() => handleSubmit()}
          >
            Update Changes{' '}
            <Spinner
              className={editSpinner ? 'd-block ms-2' : 'd-none ms-2'}
              animation='border'
              size='sm'
            />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AdminManagement
