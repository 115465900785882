import { Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Pagination, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { parse } from 'uuid'
import plus from '../../assets/icons/plus.svg'
import uploadBtn from '../../assets/icons/upload.svg'
import demoImg from '../../assets/images/demoLogoImg.png'
import DashBoard from '../../components/DashBoard/DashBoard'
import StoreFronManagementModal from '../../components/Modals/AddCompanyModal/CompanyManagementModal'
import {
  GetAdminProfileUrl,
  GetAllCompanyAPI,
  GetAllTagUrl,
  GetAllTypesUrl,
} from '../../constants/api.constants'
import detectAdBlock from '../../utils/DetectAdBlocker/DetectAdBlocker'
import Toast from '../../utils/Toast/Toast'
import './CompanyManagement.scss'


const { Option } = Select

const CompanyFrontManagements = () => {
  const [show, setShow] = useState(false)
  const [spin, setSpin] = useState(false)
  const [allTags, setAllTags] = useState([])
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const history = useHistory()
  // const [store, setStore] = useContext(StoreProvider)
  const goToStore = (id) => {
    history.push(`/companyfront/${id}`)
  }

  const HttpStatus = {
    OK: 200,

  };
  const [totalDoc, setTotalDoc] = useState(0)

  const [allCompany, setAllCompany] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [searchTag, setSearchTag] = useState('')
  const [selectedTag, setSelectedTag] = useState('')
  const [searchType, setSearchType] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const [userData, setUserData] = useState({})

  const [sort, setSort] = useState('')



 
  useEffect(() => {
    loadCompanyData();
  }, [page, search, selectedTag, selectedType, sort,userData ]);



  const loadCompanyData = async () => {
    setSpin(true)
    let url = GetAllCompanyAPI + `?page=${page}`;
    if (search.length > 0) {
      url += `&filter=${search}`
      setPage(1)
    }
    if (selectedTag.length > 0) {
      url += `&tag=${selectedTag}`
      setPage(1)
    }
    if (selectedType.length > 0) {
      url += `&type=${selectedType}`
      setPage(1)
    }
    if (sort.length > 0) {
      url += `&sort=${sort}`
    }
    try {
      const response = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })


      if (response.status === 200) {
        const parsedData = JSON.parse(response.data.data);
        setSpin(true)
        setAllCompany(parsedData);
        setTotalDoc(response?.data?.total_document)
        setSpin(false)
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      setSpin(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }




  let items = []
  let totalPage = 0
  if (totalDoc < 10) totalPage = 1
  else totalPage = Math.ceil(totalDoc / 10)
  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number == page}>
        {number}
      </Pagination.Item>
    )
  }

  return (
    <div className='row py-3'>
      <div className='col-3'>
        <DashBoard />
      </div>
      <div className='col-9'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='fw-bold'>Companyfront Management</h3>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={handleShow}
          >
            <img className='me-3' src={plus} alt='' /> Create New Company
          </button>
        </div>

        <div className='mt-4 plain-input me-2'>
          <label for=''>Search Company</label>
          <br />
          <input
            type='text'
            placeholder='search by name, email, location, phone etc.'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {spin && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Spinner animation='border' style={{ color: '#558f55' }} />
          </div>
        )}

        {!spin ? (
          <Table
            striped
            bordered
            hover
            responsive
            borderless={true}
            className='my-5 text-start'
          >
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {allCompany?.map((dt, idx) => (
                <tr key={idx} onClick={() => goToStore(dt?._id)}>
                  <td> {dt.name} </td>
                  <td>{dt.email}</td>
                  <td>{dt.phone}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !spin && (
            <h2 className='text-center my-5 text-secondary'>No Data Found!</h2>
          )
        )}

        {!spin && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Pagination
              onClick={(e) => {
                setPage(e.target.innerText)
              }}
            >
              {

              }
              {items}
            </Pagination>
          </div>
        )}
      </div>

      <CompanyManagementModal
        show={show}
        handleClose={handleClose}
        demoImg={demoImg}
        uploadBtn={uploadBtn}
        loadStoreData={loadCompanyData}
      />
    </div>
  )
}

export default CompanyFrontManagements
