import React, { createContext, useContext, useState } from "react";
// import jwt_decode from 'jwt-decode';
const AuthContext = createContext({
  user: {},
  setUser: () => { },
  adFolderPreviousId: '',
  setAdFolderPreviousId: () => { },
})

// const authChecker2 = () => {
//   console.log(AuthContext); 
// };
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})

  const login = React.useCallback((u) => {
    setUser(u)
  }, []);
  const decodeJWT = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodedData = atob(base64);
    const decodedToken = JSON.parse(decodedData);
    return decodedToken;
  };
  const isAuthenticatedFun = (token) => {
    // Get the JWT token from your authentication provider or local storage
  
    if (token) {
      // Decode the JWT token to get the expiration time
      const decodedToken = decodeJWT(token);
      // Get the current time
      const currentTime = Date.now() / 1000;
  
      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        // Token has expired
        return false;
      } else {
        // Token is valid
        return true;
      }
    } else {
      // No token found
      return false;
    }
  };
  
  // Usage:
  React.useEffect(() => {
    let token = "";
    if(localStorage.getItem('user_role') === "ADMIN"){
      token = localStorage.getItem('menu_token')
    }else if(localStorage.getItem('user_role') === "MANAGER"){
      token = localStorage.getItem('store_token')
    }else if(localStorage.getItem('user_role') === "PRODUCTADMIN"){
      token = localStorage.getItem('company_token')
    }
    
    const isAuthenticated = isAuthenticatedFun(token);
    console.log("admin token")
    
    if (isAuthenticated) {
      setUser({ _id: token })
    } else {
      setUser({})
    }
  }, [])



  return (
    <AuthContext.Provider value={{ user, setUser: login }} >
      {children}
    </AuthContext.Provider >
  )
}
export default AuthProvider;
export const useAuth = () => useContext(AuthContext);
// export const authChecker = () => authChecker2;
