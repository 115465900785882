import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { CreateAdmin, GetAllStoreAPI } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'

const NewAdminModal = ({ show, handleClose, loadAllAdmin, userData }) => {
  const [spinner, setSpinner] = useState(false)
  const [allStore, setAllStore] = useState([])
  const [store, setSelectedStoreId] = useState('');

  const Roles = {
    TICKETING_STAFF: 'ticketingstaff',
    MANAGER: 'manager',
    ADMIN: 'admin'
  };
  const AssignRoles = {
    TICKETING_STAFF: 'Ticketing Staff',
    MANAGER: 'Manager',
    ADMIN: 'Admin'
  };


  const [data, setData] = useState({
    email: '',
    name: '',
    role: 'ticketingstaff',
    phone: '',
    password: '',
  })
  const [passRetype, setPassReType] = useState('')

  const loadStoreData = async () => {
    let url = GetAllStoreAPI
    try {
      const response = await axios.get(url + '?pagesize=20', {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })


      if (response.status === 200) {
        const parsedData = JSON.parse(response.data.data);
        setAllStore(parsedData);

      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

  const handleSubmit = async () => {
    let rolemessage = "";
    if (data.role === Roles.ADMIN) {
      rolemessage = AssignRoles.ADMIN;
    } else if (data.role === Roles.MANAGER) {
      rolemessage = AssignRoles.MANAGER;
    } else if (data.role === Roles.TICKETING_STAFF) {
      rolemessage = AssignRoles.TICKETING_STAFF;
    }
    if (data.name === '') {
      Toast('err', 'Please enter your name')
      setSpinner(false)
      return
    }
    if (data.email === '') {
      Toast('err', 'Please enter a valid email')
      setSpinner(false)

      return
    }
    if (data.phone === '') {
      Toast('err', 'Please enter phone number')
      return
    }
    if (data.password === '') {
      Toast('err', 'Please enter a valid password')
      return
    }
    if (data.role === '') {
      Toast('err', 'Please enter role')
      return
    }

    if (passRetype !== data.password) {
      Toast('err', 'Password and confirm password are not same')
      setSpinner(false)

      return
    }
    let Store = null;
    if (data.role === Roles.MANAGER || data.role === Roles.TICKETING_STAFF) {
      Store = store;
    }



    try {
      const menuboard = localStorage.getItem('menu_token')
      // const response = await axios.post(CreateAdmin, data,menuboard)
      const response = await axios.post(CreateAdmin, { ...data, store: Store }, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        Toast('success', `${rolemessage} Created!`)
        handleClose()
        setSpinner(false)
        loadAllAdmin()
        setData({
          email: '',
          name: '',
          role: 'ticketingstaff',
          phone: '',
          password: '',
        })
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      // handleClose()
      setSpinner(false)

      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  useEffect(() => {
    loadStoreData()
  }, [])


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title>Add New Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {' '}
        <div className='plain-input me-2'>
          <label for=''>Full Name</label>
          <br />
          <input
            type='text'
            placeholder='please enter your name'
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </div>
        <div className='plain-input me-2 mt-2'>
          <label for=''>Email</label>
          <br />
          <input
            type='email'
            placeholder='email@email.com'
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </div>
        <div className='plain-input me-2 mt-2'>
          <label for=''>Phone</label>
          <br />
          <input
            type='text'
            value={data.phone}
            placeholder='Enter Phone Number'
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />
        </div>
        <div className='plain-dropdown  mt-2'>
          <label for=''>Role</label>
          <select onChange={(e) => setData({ ...data, role: e.target.value })}>
            <option value=''>Select Store</option>
            <option value='ticketingstaff'>Ticketing-Staff</option>
            {
              userData?.effective_role === Roles.MANAGER || (
                <>
                  <option value='manager'>Manager</option>
                  {
                    userData?.effective_role === Roles.ADMIN || (
                      <option value='admin'>Admin</option>
                    )
                  }


                </>
              )
            }

            {/* <option value='super_admin'>super admin</option>{' '} */}
          </select>
        </div>
        { }
        {
          (data.role === Roles.MANAGER) && (
            <div className='plain-dropdown  mt-2'>
              <label for=''>Store</label>
              <select onChange={(e) => setSelectedStoreId(allStore[e.target.value]._id)}>
                <option value="" >
                  Select Store
                </option>
                {allStore.map((dt, idx) => (
                  <option key={idx} value={idx}>
                    {dt.name}
                  </option>
                ))}
              </select>
            </div>
          )
        }
        {/* {
          (data.role === Roles.TICKETING_STAFF) && (
            <div className='plain-dropdown  mt-2'>
              <label for=''>Store</label>
              <select onChange={(e) => setSelectedStoreId(allStore[e.target.value]._id)}>
              <option value="" >
                  Select Store
                </option>
                {userData.effective_role == Roles.MANAGER ? (
                  allStore
                    .filter(data => data._id === userData.store)
                    .map((dt, idx) => (
                      <option key={idx} value={idx}>
                        {dt.name}
                      </option>
                    ))
                ) : (
                  allStore.map((dt, idx) => (
                    <option key={idx} value={idx}>
                      {dt.name}
                    </option>
                  ))
                )}
              </select>

            </div>
          )
        } */}
        {
          data.role === Roles.TICKETING_STAFF && (
            <div className='plain-dropdown mt-2'>
              <label htmlFor=''>Store</label>
              <select
                onChange={(e) => {
                  setSelectedStoreId(e.target.value); // Use e.target.value directly
                }}
              >
                <option value="">
                  Select Store
                </option>

                {userData.effective_role === Roles.MANAGER ? (
                  allStore
                    .filter(store => store._id === userData.store)
                    .map((store, idx) => (
                      <option key={idx} value={store._id}>
                        {store.name}
                      </option>
                    ))
                ) : (
                  allStore.map((store, idx) => (
                    <option key={idx} value={store._id}>
                      {store.name}
                    </option>
                  ))
                )}
              </select>
            </div>
          )
        }



        <div className='plain-input me-2 mt-2'>
          <label for=''>Password</label>
          <br />
          <input
            type='password'
            placeholder='minimum length 5'
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />
          {data.password.length > 0 && data.password.length < 5 && (
            <p className='text-danger'> at least 5 characters required!</p>
          )}
        </div>
        <div className='plain-input me-2 mt-2'>
          <label for=''>Re-Type Password</label>
          <br />
          <input
            type='password'
            placeholder='re-type your password'
            onChange={(e) => setPassReType(e.target.value)}
          />
          {passRetype.length > 0 && passRetype !== data.password && (
            <p className='text-danger'>password not matched!</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <button className='primary-btn-light ' onClick={handleClose}>
          Cancel
        </button>
        <button
          className='primary-btn d-flex justify-content-between align-items-center'
          onClick={() => handleSubmit()}
        >
          Add Admin{' '}
          <Spinner
            className={spinner ? 'd-block ms-2' : 'd-none ms-2'}
            animation='border'
            size='sm'
          />
        </button>
      </Modal.Footer>
    </Modal >
  )
}

export default NewAdminModal
