import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import xtype from 'xtypejs'
import { Spinner } from 'react-bootstrap'
import 'react-input-range/lib/css/index.css'
import { useParams } from 'react-router-dom'
import DashBoard from '../../components/DashBoard/DashBoard'
import { GetScreen } from '../../constants/api.constants'
import ProductComponent from '../../components/ProductComponent/ProductComponent'
import Toast from '../../utils/Toast/Toast'
import './ProductListPage.scss'

const ProductListPage = () => {
  
  const [spinner, setSpinner] = useState(false)

  const { id } = useParams()
  const [productData, setProductData] = useState()

  useEffect(() => {
    loadProductData();
  },[]);
  const loadProductData = async () => {
    setSpinner(true);
    try{
        console.log(id);
        const response = await axios.get(GetScreen + `/${id}` + '/get_products', {
            headers: {
              menuboard: localStorage.getItem('menu_token'),
            },
          })
          const temp = response.data.data.product;
          setProductData(temp);
          setSpinner(false);

    }catch (error) {
        setSpinner(false)
        Toast(
          'err',
          error.response?.data?.msg || 'Something went wrong! Try again later.'
        )
      }
  }
  return (
    <div className='row py-3'>
      <div className='col-3'>
        <DashBoard />
      </div>
      <div className='col-9'>
        <h2 className='mt-4 fw-bold d-flex justify-content-start align-items-center'>
          Products
          {spinner && <Spinner animation='border' size='sm' className='ms-2' />}
        </h2>
        {productData?.map((dt, idx) => (
            <>
            {dt.length>0 && (
              <h3 className='categoryStlye'>Category: {dt[0].categoryName}</h3 >
            )}
            {dt.map((dt, idx) => (                   
              <ProductComponent
                screenId={id}
                product={dt}
                loadProductData = {""}
                editEnable= {true}
              />
            ))}
            </>
          ))}
      </div>
      
    </div>
  )
}

export default ProductListPage
