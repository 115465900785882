import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import Toast from '../../../utils/Toast/Toast'
import { TaskAPI, GetAllStoresList, GetAdminApi } from '../../../constants/api.constants'
import './EditTicketModal.css';
function EditTicketModal({ show, handleClose, data, loadTicketData, header, userData }) {
    const history = useHistory()
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteSpinner, setDeleteSpinner] = useState(false);
    const [allAdmins, setAllAdmins] = useState([])
    const [editSpinner, setEditSpinner] = useState(false);
    const [tags, setTags] = useState('');
    const [page, setPage] = useState(1)
    const [storeList, setStoreList] = useState([])
    const [ticketData, setTicketData] = useState({
        title: "",
        description: "",
        tags: [],
        status: "",
        store_name: "",
        platform: "",
        priority: "",
        due_date: "",
        assignee: "",
    });
    const TicketStatusOptions = ['To-Do', 'In-Progress', 'Dev-Done', 'Testing', 'Complete'];
    const HttpStatus = {
        OK: 200,
      };
    const Roles = {
        TICKETING_STAFF: 'ticketingstaff',
        MANAGER: 'manager',
        ADMIN: 'admin',
        SUPER_ADMIN: "super_admin"
    };
    const getStoreList = async () => {
        if (localStorage.getItem('menu_token')) {
            try {
                const res = await axios.get(GetAllStoresList, {
                    headers: {
                        menuboard: localStorage.getItem('menu_token'),
                    },
                })
                if (res.status === 200) {
                    setStoreList(res.data.data);
                }
            } catch (err) {
                Toast('err', err.response?.data?.msg || 'Something went wrong! Try again later.'
                )
            }
        }

    }
    useEffect(() => {
        getStoreList();
        setTicketData({
            title: data?.title,
            description: data?.description,
            tags: data?.tags,
            store_name: data?.store_name,
            status: data?.status,
            platform: data.platform,
            priority: data?.priority,
            due_date: data?.due_date,
            assignee: data?.assignee
        })
    }, [data]);
    useEffect(() => {
        loadAllAdmin()
    }, [userData])
    const loadAllAdmin = async () => {

        let url = `${GetAdminApi}?page=${page}`

        // if 
        if (userData?.effective_role) {
            url += '&role=ticketingstaff'
            setPage(1)
        }

        try {
            const response = await axios.get(url, {
                headers: {
                    menuboard: localStorage.getItem('menu_token'),
                },
            })

            if (response.status === HttpStatus.OK) {
                setAllAdmins(response?.data?.data)
            } else
                throw new Error(
                    response?.data?.msg || 'Cant load admins data, try again later'
                )
        } catch (error) {

            Toast(
                'err',
                error.response?.data?.msg || 'Cant load admins data, try again later'
            )
        }

        // useEffect(() => {

        // }, [])

    }
    async function updateTicketData() {
        setEditSpinner(true)
          if (ticketData.title === '') {
            Toast('err', 'Title is required')
            setEditSpinner(false)
            return
          }
          if (ticketData.description === '') {
            Toast('err', 'Please enter description')
            setEditSpinner(false)
            return
          }
          if (ticketData.tags.length === 0) {
            Toast('err', "Please add Atleast one tag!")
            setEditSpinner(false)
            return
          }
        try {
            await axios
                .put(
                    TaskAPI + `/${data._id}`,
                    ticketData,
                    header
                )
                .then((response) => {
                    if (response.status === 200) {
                        setEditSpinner(false)
                        Toast('success', 'Ticket updated!')
                        handleClose()
                        loadTicketData()
                        setTicketData({
                            title: "",
                            description: "",
                            tags: [],
                            status: "",
                            store_name: "",
                            platform: "",
                            priority: "",
                            due_date: "",
                            assignee: "",
                        })
                    } else throw new Error(response?.data?.msg)
                })
        } catch (error) {
            setEditSpinner(false)

            Toast(
                'err',
                error.response?.data?.msg || 'Something went wrong! Try again later.'
            )
        }
    }
    function handleInput(e) {
        setTicketData({
            ...ticketData,
            [e.target.name]: e.target.value,
        })
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const enteredTag = event.target.value.trim(); // Trim leading and trailing whitespaces

            if (enteredTag) { // Check if the entered tag is not empty
                if (!ticketData?.tags.includes(enteredTag)) {
                    setTicketData({
                        ...ticketData,
                        tags: [...ticketData.tags, enteredTag],
                    });
                    setTags('');
                }
            }
        }
    }

    const handleDeleteTag = (tags) => {
        let newArr = ticketData?.tags.filter((t) => tags !== t)

        setTicketData({ ...ticketData, tags: newArr })
    }
    const handleDelete = async () => {
        setDeleteSpinner(true)
        try {
            const res = await axios.delete(TaskAPI + `/${data._id}`, header)
            if (res.status === 200) {
                Toast('success', 'Ticket has been deleted successfully')
                setDeleteModal(false)
                setDeleteSpinner(false)
                history.push('/ticketing-panel')
            } else throw new Error(res?.data?.msg)
        } catch (error) {
            Toast('err', error.response?.data?.msg)
            setDeleteModal(false)
            setDeleteSpinner(false)
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title style={{ fontSize: '22px' }}>Edit Ticket</Modal.Title>
                </Modal.Header>
                {userData?.effective_role === Roles.TICKETING_STAFF || (
                    <Modal.Body>

                        <div className='plain-input my-3'>
                            <label for=''>Title</label>
                            <br />
                            <input
                                type='text'
                                placeholder='Please input title'
                                value={ticketData.title}
                                onChange={handleInput}
                                name='title'
                            />
                        </div>
                        <div className='plain-input my-3'>
                            <label for=''>Description</label>
                            <br />
                            <textarea
                                style={{
                                    width: '100%',
                                    height: '100px',
                                    padding: '1%',
                                    border: '1px solid #afafaf',
                                    borderRadius: '5px',
                                    onClick: '1px solid #afafaf'
                                }}
                                placeholder='Please input Description'
                                value={ticketData.description}
                                onChange={handleInput}
                                name='description'
                            />
                        </div>
                        <div className='plain-input my-3'>
                            <label for=''>Tags</label>
                            <br />
                            <input
                                type='text'
                                placeholder='Please input your tags'
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                        <div className='d-flex justify-content-start align-items-center flex-wrap'>
                            {ticketData?.tags?.map((tags, idx) => (
                                <span
                                    key={idx}
                                    className=' p-2  me-2 mb-2'
                                    style={{
                                        color: 'black',
                                        backgroundColor: '#e0e0e0',
                                        borderRadius: '4px',
                                    }}
                                >
                                    {tags}
                                    <TiDelete
                                        className="delete-icon"
                                        onClick={() => handleDeleteTag(tags)}
                                    />
                                </span>
                            ))}
                        </div>
                        {/* {localStorage.getItem('menu_token') && (
                        <div className='plain-dropdown  mt-2'>
                            <label for=''>Store Name</label>
                            <select onChange={(e) => {
                                const selectedStoreId = e.target.value.split('|')[0];
                                const selectedStoreName = e.target.value.split('|')[1];
                                setTickets({ ...tickets, store_id: selectedStoreId, store_name: selectedStoreName });
                            }}>
                                <option value="">Select a store</option>

                                {(userData?.effective_role === Roles.TICKETING_STAFF || userData?.effective_role === Roles.MANAGER) ? storeList.filter((data) => data._id === userData?.store).map((store) => {
                                    return (
                                        <option value={`${store._id}|${store.name}`}>
                                            {store.name}
r                                        </option>
                                    )
                                }) : storeList.map((store) => {
                                    return (
                                        <option value={`${store._id}|${store.name}`}>
                                            {store.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    )
                    } */}


                        <div className='plain-dropdown mt-2'>
                            <label htmlFor=''>Current Status</label>
                            <select
                                onChange={(e) => setTicketData({ ...ticketData, status: e.target.value })}
                                value={ticketData.status}
                            >
                                {TicketStatusOptions.map((statusOption) => (
                                    <option key={statusOption} value={statusOption}>
                                        {statusOption}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='plain-dropdown  mt-2'>
                            <label for=''>Priority</label>
                            <select onChange={(e) => setTicketData({ ...ticketData, priority: e.target.value })}>
                                {/*  urgent, high, low, medium*/}
                                <option
                                    value='Low'
                                    selected={ticketData?.priority === 'Low' ? true : false}
                                >
                                    Low
                                </option>
                                <option
                                    value='Medium'
                                    selected={ticketData?.priority === 'Medium' ? true : false}
                                >
                                    Medium
                                </option>
                                <option
                                    value='High'
                                    selected={ticketData?.priority === 'High' ? true : false}
                                >
                                    High
                                </option>
                                <option
                                    value='Urgent'
                                    selected={ticketData?.priority === 'Urgent' ? true : false}
                                >
                                    Urgent
                                </option>
                            </select>
                        </div>
                        <div className='plain-dropdown  mt-2'>
                            <label for=''>Platform</label>
                            <select onChange={(e) => setTicketData({ ...ticketData, platform: e.target.value })}>
                                {/*  urgent, high, low, medium*/}
                                <option
                                    value='TV-App'
                                    selected={ticketData.platform === 'TV-App' ? true : false}
                                >TV-app
                                </option>
                                <option
                                    value='Mobile'
                                    selected={ticketData.platform === 'Mobile' ? true : false}
                                >
                                    Mobile</option>
                                <option
                                    value='PC'
                                    selected={ticketData.platform === 'PC' ? true : false}
                                >PC</option>
                            </select>
                        </div>

                        <div className='plain-dropdown mt-2'>
                            <label htmlFor='assignee'>Assignee</label>
                            <select
                                id='assignee'
                                onChange={(e) => setTicketData({ ...ticketData, assignee: e.target.value })}
                                value={ticketData.assignee}
                            >
                                {/*  urgent, high, low, medium*/}
                                <option disabled>Select Assignee</option>
                                {userData?.effective_role === Roles.MANAGER
                                    ? allAdmins
                                        .filter((data) => data.effective_role === Roles.TICKETING_STAFF)
                                        .map((dt, idx) => (
                                            <option key={idx} value={dt.name}>
                                                {dt.name}
                                            </option>
                                        ))
                                    : allAdmins.map((dt, idx) => (
                                        <option key={idx} value={dt.name}>
                                            {dt.name}
                                        </option>
                                    ))}
                            </select>
                        </div>


                        {/* </form> */}
                    </Modal.Body>
                )}
                {
                    userData?.effective_role === Roles.TICKETING_STAFF && (
                        <Modal.Body>

                            <div className='plain-dropdown mt-2'>
                                <label htmlFor=''>Current Status</label>
                                <select
                                    onChange={(e) => setTicketData({ ...ticketData, status: e.target.value })}
                                    value={ticketData.status}
                                >
                                    {TicketStatusOptions.map((statusOption) => (
                                        <option key={statusOption} value={statusOption}>
                                            {statusOption}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* </form> */}
                        </Modal.Body>
                    )
                }

                <Modal.Footer>

                    {/* {localStorage.getItem('user_role') === "ADMIN" && ( */}
                    {
                        userData?.effective_role === Roles.TICKETING_STAFF || (
                            <button
                                className='danger-btn-light'
                                onClick={() => {
                                    handleClose()
                                    setDeleteModal(true)
                                }}
                            >
                                Delete
                            </button>
                        )
                    }


                    <button className='primary-btn-light' onClick={handleClose}>
                        Close
                    </button>
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={() => updateTicketData()}
                        type='submit'
                    >
                        Save Changes{' '}
                        {editSpinner && (
                            <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <h4 className='fw-bold text-danger'>Caution!</h4>
                    <h5>Are you sure you want to delete the ticket?</h5>
                </Modal.Body>
                <Modal.Footer style={{ border: 'none' }}>
                    <button
                        className='primary-btn-light'
                        onClick={() => setDeleteModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={() => handleDelete()}
                        type='submit'
                    >
                        Delete{' '}
                        {deleteSpinner && (
                            <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditTicketModal