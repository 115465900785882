import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import {
  GetScreenAdLog
} from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'


const AdReportModal = ({ show, handleClose, storeId, adId }) => {
    
    const [adReports,setAdReports] = useState([]);
    useEffect(() => {
        loadAdReport()
        // console.log(ad);
      }, [])
    const loadAdReport = async()=>{
        const url = GetScreenAdLog + `${storeId}/ad/${adId}`;
        // console.log(url);
        try{
            const res = await axios.get(url,{
                headers: {
                  menuboard: localStorage.getItem('menu_token'),
                },
              });
            if(res.status === 200){
                // console.log(res.data.data);
                setAdReports(res.data.data);
            }
        }catch(err){
            Toast('err', err.response?.data?.msg || 'Something went wrong! Try again later.')
        }
    }
    return(
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton style={{ border: 'none' }}>
                <Modal.Title style={{ fontSize: '32px'}}>Ad Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {adReports?.map((adReport, idx) => (
                <div className='d-flex justify-content-start align-items-end'
                    style={
                    {
                        marginBlock:'2%',
                        marginInline:'2%', 
                        border:'1px solid #cfcfcf',
                        boxShadow:'5px 5px 2.5px #dfecdf',
                        borderRadius:'20px', 
                        padding:'2%',
                    }}
                
                >
                    <div style={{marginInline:'5%'}}>
                        <h2>Screen {idx + 1}</h2>
                        <h6>Unique ID: {adReport._id.unique_id}</h6>
                    </div>
                    <div className='d-flex flex-row'
                    style={{marginBlock:'auto', height:'100%'}}>
                        <div>
                            <h6 className='fw-bold'> Played : &nbsp;</h6>
                            <h6 className='fw-bold'> Count&nbsp; : </h6>
                        </div>
                        <div>
                            <h6 className='fw-normal'> {adReport.total_duration} sec</h6>
                            <h6 className='fw-normal'> 
                                {adReport.total_count} &nbsp;
                                {adReport.total_count>1?('times'):('time')}
                            </h6>
                        </div>
                    </div>        
                </div>
                ))}
                
            </Modal.Body>
        </Modal>
    )
}
export default AdReportModal