import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { FileUploadEnd, TaskCreate, GetAllStoresList, TaskAPI, GetAdminApi } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import demoImg from '../../../assets/images/demoLogoImg.png'
import "./AddTicketFrontal.scss"
// import DatePicker from "react-datepicker";
// import 'react-datepicker/dist/react-datepicker.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// import { DatePicker } from 'rsuite';

export default function AddTicketFrontal({
  show,
  handleClose,
  loadTicketData,
  header,
  storeList,
  userData
}) {
  const [editSpinner, setEditSpinner] = useState(false)
  // const [photoUrl, setPhotoUrl] = useState('')
  // const [dueDate, setDueDate] = useState(new Date());
  const [ticketImages, setTicketImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [allAdmins, setAllAdmins] = useState([])
  const [page, setPage] = useState(1)
  const today = new Date().toISOString().slice(0, 10);
  const [tickets, setTickets] = useState(
    {
      title: "",
      description: "",
      tags: [],
      status: "To-Do",
      platform: "TV-app",
      store_id: "",
      priority: "Low",
      due_date: "",
      store_name: "",
      assignee: "",
      subtask: []
    })
  const [photoSpinner, setPhotoSpinner] = useState(false)
  const fileInputRef = useRef(null);
  const [assign, setSelectedAssign] = useState('');
  const Roles = {
    TICKETING_STAFF: 'ticketingstaff',
    MANAGER: 'manager',
    ADMIN: 'admin'
  };

  // useEffect(() => {
  //   getStoreList()
  // }, [])

  // const getStoreList = async ()=>{
  //   try{
  //       const res = await axios.get(GetAllStoresList,{
  //         headers: {
  //           menuboard: localStorage.getItem('menu_token'),
  //         },
  //       })
  //       if(res.status === 200){
  //         setStoreList(res.data.data);
  //       }
  //   }catch(err){
  //     Toast('err', err.response?.data?.msg || 'Something went wrong! Try again later.'
  //     )
  //   }
  // }
  // function handleDateInput(date) {
  //    setDueDate(date);
  //    const dated = new Date(date);
  //    let updatedValue = {"due_date": {dated}}
  //    setTickets({
  //     ...tickets,
  //     ...updatedValue
  //   });
  // }
  // const handleImageUpload = async (e) => {
  //   setPhotoSpinner(true)
  //   const file = e.target.files[0]

  //   const formData = new FormData()
  //   formData.append('files', file)

  //   try {
  //     const res = await axios.post(FileUploadEnd, formData, header)
  //     if (res.status === 200) {
  //       setPhotoUrl(res.data?.files[0]?.path)
  //       setPhotoSpinner(false)
  //       Toast('success', 'File uploaded successfully')
  //     }
  //   } catch (error) {
  //     setPhotoSpinner(false)
  //     setPhotoUrl(null)
  //   }
  // }
  const token = localStorage.getItem('menu_token');

  useEffect(() => {
    if (token) {
      loadAllAdmin()
    }
  }, [userData])
  const loadAllAdmin = async () => {

    let url = `${GetAdminApi}?page=${page}`

    // if 
    if (userData?.effective_role) {
      url += '&role=ticketingstaff'
      setPage(1)
    }

    try {
      const response = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setAllAdmins(response.data.data)
      } else
        throw new Error(
          response?.data?.msg || 'Cant load admins data, try again later'
        )
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Cant load admins data, try again later'
      )
    }

    // useEffect(() => {

    // }, [])

  }

  function handleInput(e) {
    setTickets({
      ...tickets,
      [e.target.name]: e.target.value,
    })
  }

  const [tags, setTags] = useState('')

  async function postTicketData() {
    setEditSpinner(true)
    if (tickets.store_name === '' && localStorage.getItem('menu_token')) {
      Toast('err', 'Please select a store')
      setEditSpinner(false)
      return
    }
    if (tickets.title === '') {
      Toast('err', 'Please enter title')
      setEditSpinner(false)
      return
    }
    if (tickets.description === '') {
      Toast('err', 'Please enter description')
      setEditSpinner(false)
      return
    }
    if (tickets.tags.length === 0) {
      Toast('err', "Please add atleast one tag!")
      setEditSpinner(false)
      return
    }
    // let temp = {
    //   ...(tickets.store_id ? {} : { store_id: userData?.store }),
    //   ...tickets
    // }

    // return
    try {
      await axios
        .post(TaskCreate, tickets, header)
        .then((response) => {
          if (response.status === 200) {
            const id = response.data.data._id;
            selectedImages.forEach(file => {
              const formData = new FormData();
              formData.append('file', file)
              uploadPhotoAPI(formData, id);
            });
            setEditSpinner(false)
            Toast('success', 'Ticket Created!');
            handleClose();
            loadTicketData();
            setTickets({
              title: "",
              description: "",
              tags: [],
              status: "To-Do",
              store_name: "",
              platform: "TV-app",
              store_id: "",
              priority: "Low",
              due_date: "",
              assignee: "",
              subtask: []
            })
          } else throw new Error(response?.data?.msg)
        })
    } catch (error) {
      setEditSpinner(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const enteredTag = event.target.value.trim();
      if (enteredTag) {
        if (tickets?.tags.includes(event.target.value) === false) {
          setTickets({
            ...tickets,
            tags: [...tickets.tags, event.target.value],
          })
          setTags('')
        }
      }
    }
  }

  const handleDeleteTag = (tags) => {
    let newArr = tickets?.tags.filter((t) => tags !== t)

    setTickets({ ...tickets, tags: newArr })
  }
  const handleImageUpload = async (event) => {
    // setPhotoSpinner(true);
    const files = Array.from(event.target.files);
    if (files.length > 5) {
      Toast(
        'err',
        'You can not add more than 5 photos'
      )
      // setPhotoSpinner(false);
      return;
    } else {
      setSelectedImages(files);
      // files.forEach(file => {
      //     const formData = new FormData();
      //     formData.append('file',file)
      //     uploadPhotoAPI(formData);

      // });
      // setPhotoSpinner(false);      
    }

  }
  const clearSelectedImages = () => {
    setSelectedImages([]);
    fileInputRef.current.value = null;
  };
  const uploadPhotoAPI = async (formData, id) => {
    try {
      const response = await axios.post(TaskAPI + `/${id}/pictures`, formData, header)
      if (response.status == 200) {
        // Toast('success', "Photo Uploaded Successfully");
        // loadTicketImages();
      }
    }
    catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }

  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>
            Add New Ticket
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className='d-flex justify-content-start align-items-end'>
            <img
              src={photoUrl || demoImg}
              alt=''
              height='100'
              width='200'
              className='me-4'
            />

            <Form.Group className='' controlId='formBasicEmail'>
              <Form.Label>
                Image
                {photoSpinner && (
                  <Spinner className='ms-1' animation='border' size='sm' />
                )}
              </Form.Label>
              <Form.Control type='file' onChange={(e) => handleImageUpload(e)} />
            </Form.Group>
          </div> */}
          <div className='plain-input my-3'>
            <label for=''>Title</label>
            <br />
            <input
              type='text'
              placeholder='Please input title'
              value={tickets.title}
              onChange={handleInput}
              name='title'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Description</label>
            <br />
            <textarea
              class="plain-input"
              style={{
                width: '100%',
                height: '100px',
                padding: '1%',
                border: '1px solid #afafaf',
                borderRadius: '5px',
                onClick: '1px solid #afafaf'
              }}
              placeholder='Please input Description'
              value={tickets.description}
              onChange={handleInput}
              name='description'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Tags</label>
            <br />
            <input
              type='text'
              placeholder='Please input your tags'
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
          <div className='d-flex justify-content-start align-items-center flex-wrap'>
            {tickets?.tags?.map((tags, idx) => (
              <span
                key={idx}
                className=' p-2  me-2 mb-2'
                style={{
                  color: 'black',
                  backgroundColor: '#e0e0e0',
                  borderRadius: '4px',
                }}
              >
                {tags}
                <TiDelete
                  style={{
                    marginLeft: '5px',
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteTag(tags)}
                />
              </span>
            ))}
          </div>
          {localStorage.getItem('menu_token') && (
            <div className='plain-dropdown  mt-2'>
              <label for=''>Store Name</label>
              <select onChange={(e) => {
                const selectedStoreId = e.target.value.split('|')[0];
                const selectedStoreName = e.target.value.split('|')[1];
                setTickets({ ...tickets, store_id: selectedStoreId, store_name: selectedStoreName });
              }}>
                <option value="">Select a store</option>

                {(userData?.effective_role === Roles.TICKETING_STAFF || userData?.effective_role === Roles.MANAGER) ? storeList.filter((data) => data._id === userData?.store).map((store) => {
                  return (
                    <option value={`${store._id}|${store.name}`}>
                      {store.name}                    </option>
                  )
                }) : storeList.map((store) => {
                  return (
                    <option value={`${store._id}|${store.name}`}>
                      {store.name}
                    </option>
                  )
                })}
              </select>
            </div>
          )
          }

          <div className='plain-dropdown  mt-2'>
            <label for=''>Current Status</label>
            <select onChange={(e) => setTickets({ ...tickets, status: e.target.value })}>
              {/* To Do, In progress, Dev Done, Testing, Complete */}
              <option value='To-Do'>To-Do</option>
              <option value='In-Progress'>In-Progress</option>
              <option value='Dev-Done'>Dev-Done</option>
              <option value='Testing'>Testing</option>
              <option value='Complete'>Complete</option>{' '}
            </select>
          </div>
          <div className='plain-input my-3'>
            <label for=''>Due Date</label>
            <br />
            <input
              type="date"
              onChange={handleInput}
              value={tickets.due_date}
              name='due_date'
              min={today}
            />
            {/* <DatePicker
              showIcon
              selected={dueDate}
              dateFormat="d MMM yyyy"
              onChange={(date) => handleDateInput(date)}
            /> */}
          </div>
          <div className='plain-dropdown  mt-2'>
            <label for=''>Priority</label>
            <select onChange={(e) => setTickets({ ...tickets, priority: e.target.value })}>
              {/*  urgent, high, low, medium*/}
              <option value='Low'>Low</option>
              <option value='Medium'>Medium</option>
              <option value='High'>High</option>
              <option value='Urgent'>Urgent</option>
            </select>
          </div>
          <div className='plain-dropdown  mt-2'>
            <label for=''>Platform</label>
            <select onChange={(e) => setTickets({ ...tickets, platform: e.target.value })}>
              {/*  urgent, high, low, medium*/}
              { }
              <option value='TV-app'>TV-app</option>
              <option value='Mobile'>Mobile</option>
              <option value='PC'>PC</option>
            </select>
          </div>
          <div className='plain-dropdown  mt-2'>
            <label for=''>Assignee</label>
            <select onChange={(e) => setTickets({ ...tickets, assignee: (e.target.value) })}>
              {/*  urgent, high, low, medium*/}
              <option >
                Select Assigner
              </option>
              {userData?.effective_role === Roles.MANAGER ? allAdmins.filter(data => data.effective_role === Roles.TICKETING_STAFF).map((dt, idx) => (
                <option key={idx} value={dt.name}>
                  {dt.name}
                </option>
              )) : allAdmins.map((dt, idx) => (
                <option key={idx} value={dt.name}>
                  {dt.name}
                </option>
              ))}
            </select>
          </div>
          <div className='plain-input my-3'>
            <label>Photos</label><br />
            <div className="fileInputContainer">
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
                ref={fileInputRef}
                className="fileInput"
              />
              <label htmlFor="fileInput" className="fileInputLabel">
                <span>Choose Photos</span>
              </label>
            </div>
            {selectedImages.length > 0 && (
              <div className="imageContainer">
                <h2 className="subheading">Selected Images:</h2>
                <div className="imagesWrapper">
                  {selectedImages.map((image, index) => (
                    <div key={index} className="imageItem">
                      <img src={URL.createObjectURL(image)} alt={`Selected ${index}`} className="image" />
                    </div>
                  ))}
                </div>
                <button onClick={clearSelectedImages} className="clearButton">Clear</button>
              </div>
            )}
          </div>


          {/* </form> */}
        </Modal.Body>
        <Modal.Footer>
          <button className='primary-btn-light' onClick={handleClose}>
            Close
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => postTicketData()}
            type='submit'
          >
            Add Ticket{' '}
            {editSpinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
