import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import Toast from '../../../utils/Toast/Toast'
import { TaskAPI } from '../../../constants/api.constants'

function EditSubTicketModal({show, handleClose, data,ticketID, loadSubTicketData}) {
    const history = useHistory()
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteSpinner, setDeleteSpinner] = useState(false);

    const [editSpinner, setEditSpinner] = useState(false);
    const [subTicketData, setSubTicketData] = useState({
        description: "",
        status:"",
    })
    useEffect(()=>{
        setSubTicketData({
            description: data?.description,
            status: data?.status,
        })
    },[data]);
    async function updateSubTicketData(){
        setEditSpinner(true)
        try {
            console.log(subTicketData)
            console.log(TaskAPI+`/${ticketID}/subtasks/${data._id}`)
            await axios
              .put(
                TaskAPI+`/${ticketID}/subtasks/${data._id}`,
                subTicketData,
                {
                  headers: {
                    menuboard: localStorage.getItem('menu_token'),
                  },
                }
              )
              .then((response) => {
                if (response.status === 200) {
                  setEditSpinner(false)
                  Toast('success', 'Sub-Task updated!')
                  handleClose()
                  loadSubTicketData()
                  setSubTicketData({                      
                      description: "",
                      status: "To-Do",
                  })
                } else throw new Error(response?.data?.msg)
              })
          } catch (error) {
            setEditSpinner(false)
      
            Toast(
              'err',
              error.response?.data?.msg || 'Something went wrong! Try again later.'
            )
          }
    }
    const handleDelete = async () =>{
        setDeleteSpinner(true)
        try {
          const res = await axios.delete(TaskAPI+`/${ticketID}/subtasks/${data._id}`, {
            headers: {
              menuboard: localStorage.getItem('menu_token'),
            },
          })
          if (res.status === 200) {
            Toast('success', 'Sub-Task has been deleted successfully')
            setDeleteModal(false)
            setDeleteSpinner(false)
            loadSubTicketData();
          } else throw new Error(res?.data?.msg)
        } catch (error) {
          Toast('err', error.response?.data?.msg)
          setDeleteModal(false)
          setDeleteSpinner(false)
        }
    }
    function handleInput(e) {
        setSubTicketData({
          ...subTicketData,
          [e.target.name]: e.target.value,
        })
      }
    return(
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title style={{ fontSize: '22px' }}>Edit Sub-Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='plain-input my-3'>
                    <label for=''>Description</label>
                    <br />
                    <input
                    type='text'
                    placeholder='Please input Description'
                    value={subTicketData.description}
                    onChange={handleInput}
                    name='description'
                    />
                </div>
                <div className='plain-dropdown  mt-2'>
                    <label for=''>Current Status</label>
                    <select 
                        onChange={(e) => setSubTicketData({ ...subTicketData, status: e.target.value })}
                        value = {subTicketData.status}
                    >
                    {/* To Do, In progress, Dev Done, Testing, Complete */}
                    <option 
                        value='To-Do'
                        selected = {subTicketData?.status === 'To-Do'? true : false}
                    >
                        To-Do</option>
                    <option 
                        value='In-Progress'
                        selected = {subTicketData?.status === 'In-Progress'? true : false}
                    >
                        In-Progress
                        </option>
                    <option 
                        value='Dev-Done'
                        selected = {subTicketData?.status === 'Dev-Done'? true : false}  
                    >   
                        Dev-Done
                    </option>
                    <option 
                        value='Testing'
                        selected = {subTicketData?.status === 'Testing'? true : false}
                    >
                        Testing
                    </option>
                    <option 
                        value='Complete'
                        selected = {subTicketData?.status === 'Complete'? true : false}
                    >
                            Complete
                    </option>{' '}
                    </select>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='danger-btn-light'
                        onClick={() => {
                            handleClose()
                            setDeleteModal(true)
                        }}
                    >
                        Delete
                    </button>
                    <button className='primary-btn-light' onClick={handleClose}>
                        Close
                    </button>
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={() => updateSubTicketData()}
                        type='submit'
                    >
                        Save Changes{' '}
                        {editSpinner && (
                        <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>
                    </Modal.Footer>
        </Modal>
            <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <h4 className='fw-bold text-danger'>Caution!</h4>
                    <h5>Are you sure you want to delete the Sub-Task?</h5>
                </Modal.Body>
                <Modal.Footer style={{ border: 'none' }}>
                    <button
                        className='primary-btn-light'
                        onClick={() => setDeleteModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={() => handleDelete()}
                        type='submit'
                    >
                        Delete{' '}
                        {deleteSpinner && (
                        <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditSubTicketModal;