import React from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './SideBar.scss'
import { GetAdminProfileUrl } from '../../constants/api.constants'
import { useState } from 'react'
import icon from '../../assets/icons/icon.svg'
import icon1 from '../../assets/icons/icon1.svg'
import icon2 from '../../assets/icons/icon2.svg'
import icon3 from '../../assets/icons/icon3.svg'
import icon4 from '../../assets/icons/icon4.svg'
import { Roles } from "../../constants/roles"

const SideBar = () => {
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState(true);

  const UserRole = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    MANAGER: 'manager',
    TICKETING_STAFF: 'ticketingstaff',
  };

  useEffect(() => {
    getAdminInfo()
  }, [])

  const getAdminInfo = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setUserData(response?.data?.data)
      } else throw new Error(response?.data?.msg)
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (loading && (userData && userData?.effective_role === Roles.TICKETING_STAFF)) {
    // You can render a loading spinner or any other loading indicator here
    return (
      <div>
        <div className=''>
          <ul className='list-group sticky-top  '>
            <NavLink as='li' className='list-group-item' to='/' exact>
              <img src={icon} alt="icons" />


              <span className='ps-3'> DashBoard</span>
            </NavLink>
            <NavLink
              as='li'
              className='list-group-item'
              to='/theme-management'
              exact
            >
              <img src={icon4} alt="" />
              <span className='ps-3'>Theme</span>
            </NavLink>
            <NavLink as='li' className='list-group-item' to='/ticketing-panel' exact>
              <svg
                viewBox="0 0 512 512"
                fill="#000"
                height="1.7em"
                width="1.7em"
              >
                <path d="M490.18 181.4l-44.13-44.13a20 20 0 00-27-1 30.81 30.81 0 01-41.68-1.6 30.81 30.81 0 01-1.6-41.67 20 20 0 00-1-27L330.6 21.82a19.91 19.91 0 00-28.13 0l-70.35 70.34a39.87 39.87 0 00-9.57 15.5 7.71 7.71 0 01-4.83 4.83 39.78 39.78 0 00-15.5 9.58l-180.4 180.4a19.91 19.91 0 000 28.13L66 374.73a20 20 0 0027 1 30.69 30.69 0 0143.28 43.28 20 20 0 001 27l44.13 44.13a19.91 19.91 0 0028.13 0l180.4-180.4a39.82 39.82 0 009.58-15.49 7.69 7.69 0 014.84-4.84 39.84 39.84 0 0015.49-9.57l70.34-70.35a19.91 19.91 0 00-.01-28.09zm-228.37-29.65a16 16 0 01-22.63 0l-11.51-11.51a16 16 0 0122.63-22.62l11.51 11.5a16 16 0 010 22.63zm44 44a16 16 0 01-22.62 0l-11-11a16 16 0 1122.63-22.63l11 11a16 16 0 01.01 22.66zm44 44a16 16 0 01-22.63 0l-11-11a16 16 0 0122.63-22.62l11 11a16 16 0 01.05 22.67zm44.43 44.54a16 16 0 01-22.63 0l-11.44-11.5a16 16 0 1122.68-22.57l11.45 11.49a16 16 0 01-.01 22.63z" />
              </svg>

              <span className='ps-3'> Ticketing Panel</span>
            </NavLink>
          </ul>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className=''>
        <ul className='list-group sticky-top  '>
          <NavLink as='li' className='list-group-item' to='/' exact>
            <img src={icon} alt="icons" />


            <span className='ps-3'> DashBoard</span>
          </NavLink>
          {userData && (userData?.effective_role !== UserRole?.TICKETING_STAFF) && (
            <NavLink
              as='li'
              className='list-group-item'
              to='/admin-management'
              exact
            >
              <img src={icon1} alt="icons" />

              <span className='ps-3'>User Management</span>
            </NavLink>
          )}
            
          {userData?.effective_role == UserRole.TICKETING_STAFF || (
            <NavLink
              as='li'
              className='list-group-item'
              to='/storefront-management'
              exact
            >
              <img src={icon2} alt="" />

              <span className='ps-3'>Storefront Management</span>
            </NavLink>
          )}
          {userData?.effective_role == UserRole.TICKETING_STAFF || (
            <NavLink
              as='li'
              className='list-group-item'
              to='/ad-management'
              exact
            >
              <img src={icon3} alt="" />
              <span className='ps-3'>Ad Management</span>
            </NavLink>
          )}
          <NavLink
            as='li'
            className='list-group-item'
            to='/theme-management'
            exact
          >
            <img src={icon4} alt="" />
            <span className='ps-3'>Theme</span>
          </NavLink>
          <NavLink as='li' className='list-group-item' to='/ticketing-panel' exact>
            <svg
              viewBox="0 0 512 512"
              fill="#000"
              height="1.7em"
              width="1.7em"
            >
              <path d="M490.18 181.4l-44.13-44.13a20 20 0 00-27-1 30.81 30.81 0 01-41.68-1.6 30.81 30.81 0 01-1.6-41.67 20 20 0 00-1-27L330.6 21.82a19.91 19.91 0 00-28.13 0l-70.35 70.34a39.87 39.87 0 00-9.57 15.5 7.71 7.71 0 01-4.83 4.83 39.78 39.78 0 00-15.5 9.58l-180.4 180.4a19.91 19.91 0 000 28.13L66 374.73a20 20 0 0027 1 30.69 30.69 0 0143.28 43.28 20 20 0 001 27l44.13 44.13a19.91 19.91 0 0028.13 0l180.4-180.4a39.82 39.82 0 009.58-15.49 7.69 7.69 0 014.84-4.84 39.84 39.84 0 0015.49-9.57l70.34-70.35a19.91 19.91 0 00-.01-28.09zm-228.37-29.65a16 16 0 01-22.63 0l-11.51-11.51a16 16 0 0122.63-22.62l11.51 11.5a16 16 0 010 22.63zm44 44a16 16 0 01-22.62 0l-11-11a16 16 0 1122.63-22.63l11 11a16 16 0 01.01 22.66zm44 44a16 16 0 01-22.63 0l-11-11a16 16 0 0122.63-22.62l11 11a16 16 0 01.05 22.67zm44.43 44.54a16 16 0 01-22.63 0l-11.44-11.5a16 16 0 1122.68-22.57l11.45 11.49a16 16 0 01-.01 22.63z" />
            </svg>

            <span className='ps-3'> Ticketing Panel</span>
          </NavLink>
        </ul>
      </div>
    </div>
  )
}

export default SideBar
