import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import demoImg from '../../../assets/images/demoLogoImg.png'
import {
  DeleteStoreEnd,
  FileUploadEnd,
  StoreEdit,
} from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import { LocationUrl } from '../../../constants/const'

const EditStoreModal = ({ show, handleClose, data, loadStoreData, essentialId }) => {
  const history = useHistory()
  console.log(data);
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteSpinner, setDeleteSpinner] = useState(false)

  const [editSpinner, setEditSpinner] = useState(false)
  const [storeData, setStoreData] = useState({
    name: '',
    manager: '',
    phone: '',
    email: '',
    address: '',
    footer_left: '',
    footer_center: '',
    social_link: '',
    icon: '',
    tag: [],
    removable_words: [],
    type: '',
    api_key: '',
    company_id: '',
    store_id: '',
    product_slider_interval: 30,
    font: '',
    sort: '',
    greenLineApi:false,
         
  })
  const [photoSpinner, setPhotoSpinner] = useState(false)
  const [tag, setTag] = useState('')
  const [rTag, setrTag] = useState('')


  useEffect(() => {
    console.log( 'greenLineApi',data?.greenLineApi )
    setStoreData({
      greenLineApi:data?.greenLineApi,
      name: data?.name,
      manager: data?.manager,
      phone: data?.phone,
      email: data?.email,
      address: data?.address || '',
      footer_left: data?.footer_left || '',
      footer_center: data?.footer_center || '',
      social_link: data?.social_link || '',
      icon: data?.icon || '',
      tag: data?.tag,
      removable_words: data?.removable_words,
      font: data?.font,
      type: data?.type,
      api_key: data?.api_key,
      company_id: essentialId?.companyId,
      store_id: essentialId?.storeId,
      product_slider_interval: data?.product_slider_interval,
      sort: data?.sort,
    })
  }, [data])

  const handleInput = (e) => {
    const { name, type, checked, value } = e.target;
    setStoreData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }

  async function postStoreData() {
    setEditSpinner(true)

    if (storeData.name === '') {
      Toast('err', 'Please enter store name')
      setEditSpinner(false)

      return
    }
    if (storeData.manager === '') {
      Toast('err', 'Please enter manager')
      setEditSpinner(false)

      return
    }
    if (storeData.phone === '') {
      Toast('err', 'Please enter manager\'s phone number')
      setEditSpinner(false)
      return
    }
    if (storeData.email === '') {
      Toast('err', 'Please enter email')
      setEditSpinner(false)
      return
    }
    if (storeData.address === '') {
      Toast('err', 'Please enter address')
      setEditSpinner(false)
      return
    }
    if (storeData.tag === 0) {
      Toast('err', 'Please enter at least 1 tag')
      setEditSpinner(false)
      return
    }
    if (storeData.type.length === 0) {
      Toast('err', 'Please enter store type')
      setEditSpinner(false)
      return
    }
    if (storeData.api_key === '') {
      Toast('err', 'APi key must be provided')
      setEditSpinner(false)
      return
    }

    if (storeData.product_slider_interval === '') {
      Toast('err', 'Please enter Product Slider Interval')
      setEditSpinner(false)
      return
    }
    if (storeData.company_id === '') {
      Toast('err', 'Please enter Company ID')
      setEditSpinner(false)
      return
    }
    if (storeData.store_id === '') {
      Toast('err', 'Please enter Store ID')
      setEditSpinner(false)
      return
    }

    try {
      console.log(storeData)
      await axios
        .put(
          StoreEdit,
          { ...storeData, id: data?._id },
          {
            headers: {
              menuboard: localStorage.getItem('menu_token'),
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setEditSpinner(false)
            Toast('success', 'Store updated!')
            handleClose()
            loadStoreData()
            setStoreData({
              name: '',
              greenLineApi:false,
              manager: '',
              phone: '',
              email: '',
              address: '',
              footer_left: '',
              footer_center: '',
              store_id: '',
              company_id: '',
              icon: '',
              tag: [],
              type: '',
              api_key: '2d108b5e-ec42-45cb-a0cf-c5f432ea637a',
              product_slider_interval: 30,
              sort: '',
            })
          } else throw new Error(response?.data?.msg)
        })
    } catch (error) {
      setEditSpinner(false)

      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

  const handleSort = (type) => {
    if (type === 'Alphabetical')
      setStoreData({ ...storeData, sort: 'name_ascending' });
    else if (type === 'THC Ascending')
      setStoreData({ ...storeData, sort: 'thc_ascending' });
    else
      setStoreData({ ...storeData, sort: 'thc_descending' });
  }

  const handleImageUpload = async (e) => {
    setPhotoSpinner(true)
    const file = e.target.files[0]

    const formData = new FormData()
    formData.append('files', file)

    try {
      const res = await axios.post(FileUploadEnd, formData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (res.status === 200) {
        // setPhotoUrl(res.data?.files[0]?.path)
        setStoreData({ ...storeData, icon: res?.data?.files[0].path })

        setPhotoSpinner(false)
        Toast('success', 'Photo uploaded successfully')
      }
    } catch (error) {
      setPhotoSpinner(false)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (storeData?.tag.includes(event.target.value) === false) {
        setStoreData({
          ...storeData,
          tag: [...storeData.tag, event.target.value],
        })
        setTag('')
      }
    }
  }

  const handleKeyDownForRw = (event) => {
    if (event.key === 'Enter') {
      if (storeData?.removable_words.includes(event.target.value) === false) {
        setStoreData({
          ...storeData,
          removable_words: [...storeData.removable_words, event.target.value],
        })
        setrTag('')
      }
    }
  }

  const handleDeleteTagForRw = (tag) => {
    let newArr = storeData?.removable_words.filter((t) => tag !== t)

    setStoreData({ ...storeData, removable_words: newArr })
  }

  const handleDeleteTag = (tag) => {
    let newArr = storeData?.tag.filter((t) => tag !== t)

    setStoreData({ ...storeData, tag: newArr })
  }

  const handleDelete = async () => {
    setDeleteSpinner(true)
    try {
      const res = await axios.delete(DeleteStoreEnd + `?_id=${data?._id}`, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (res.status === 200) {
        Toast('success', 'Store has been deleted successfully')
        setDeleteModal(false)
        setDeleteSpinner(false)
        history.push('/storefront-management')
      } else throw new Error(res?.data?.msg)
    } catch (error) {
      Toast('err', error.response?.data?.msg)
      setDeleteModal(false)
      setDeleteSpinner(false)
    }
  }
  const [AllLocations ,setAllLocations  ] = useState( )

  const getLocations = async ()=>{
    try{
       await axios.get(LocationUrl)
       .then((response) => {
          if (response.status === 200) {
            setAllLocations(response.data)
          }
        })
       .catch((error) => {
          console.log(error);
        });

    }catch(e){

    }
   }
 useEffect(()=>{

  getLocations()

 },[])
  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>Edit Store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Store Brand Icon / Logo</h6>
          <div className='d-flex justify-content-start align-items-end'>
            <img
              src={storeData?.icon || demoImg}
              alt='demoImg'
              height='100'
              width='100'
              className='me-4'
            />

            <Form.Group className='' controlId='formBasicEmail'>
              <Form.Label>
                <strong>Image </strong> (aspect ratio should be 1:1. e.g. 512px
                x 512px)
                {photoSpinner && (
                  <Spinner className='ms-1' animation='border' size='sm' />
                )}
              </Form.Label>
              <Form.Control
                type='file'
                onChange={(e) => handleImageUpload(e)}
              />
            </Form.Group>
          </div>

      
          <div className=' my-3'>
            <label for='' className=' mr-3'>Use GreenLine </label>
              
            <input
              type='checkbox'
              disabled={false}
              style={{marginLeft:'10px'}}
               checked={storeData.greenLineApi}
          
              value={storeData.greenLineApi}
              onChange={handleInput}
              name='greenLineApi'
            />
          </div>
          { 
                !storeData?.greenLineApi &&     

          <div className='plain-dropdown mt-3'>
            <label for=''>Select Store*</label>
            <select
              onChange={(e) => {
                const selectedOption = e.target.selectedOptions[0];
                const { name, id } = selectedOption.dataset;
                setStoreData({
                  ...storeData,
                  name: name,
                  short_id: id,
                  location_id :id
                });
              }}
            >
              <option value='' hidden>
                not selected
              </option>
              {AllLocations?.map((c, idx) => (
                <option value={c} key={idx} data-name={c.name} data-id={c.id}>
                  {c?.name}
                </option>
              ))}
            </select>
          </div>
          }
          <div className='plain-input my-3'>
            <label for=''>Store Name*</label>
            <br />
            <input
              type='text'
              disabled={true}
              placeholder='Please input store name'
              value={storeData.name}
              onChange={handleInput}
              name='name'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Store Manager / POC*</label>
            <br />
            <input
              type='text'
              placeholder='Name of manager'
              value={storeData.manager}
              onChange={handleInput}
              name='manager'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Store Phone*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your phone'
              value={storeData.phone}
              onChange={handleInput}
              name='phone'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Store Email*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your email'
              value={storeData.email}
              onChange={handleInput}
              name='email'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Address / Location*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your address'
              value={storeData.address}
              onChange={handleInput}
              name='address'
            />
          </div>

          <div className='plain-input my-3'>
            <label for=''>Tags*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your tag'
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
          <div className='d-flex justify-content-start align-items-center flex-wrap'>
            {storeData?.tag?.map((tag, idx) => (
              <span
                key={idx}
                className=' p-2  me-2 mb-2'
                style={{
                  color: 'black',
                  backgroundColor: '#e0e0e0',
                  borderRadius: '4px',
                }}
              >
                {tag}
                <TiDelete
                  style={{
                    marginLeft: '5px',
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            ))}
          </div>

          <div className='plain-input my-3'>
            <label for=''>Type*</label>
            <br />
            <input
              type='text'
              placeholder='Please input store type'
              value={storeData.type}
              onChange={(e) =>
                setStoreData({ ...storeData, type: e.target.value })
              }
              name='address'
            />
          </div>

          <div className='plain-input my-3'>
            <label htmlFor=''>Product Slider Interval</label>
            <br />
            <input
              type='number'
              placeholder='How frequently the product page will change (In Seconds)'
              value={storeData.product_slider_interval}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  product_slider_interval: e.target.value,
                })
              }
              name='product_slider_interval'
            />
          </div>

          <div className='plain-input my-3'>
            <label for=''>Removable Words*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your removable tag'
              value={rTag}
              onChange={(e) => setrTag(e.target.value)}
              onKeyDown={(e) => handleKeyDownForRw(e)}
            />
          </div>

          <div className='d-flex justify-content-start align-items-center flex-wrap'>
            {storeData?.removable_words?.map((tag, idx) => (
              <span
                key={idx}
                className=' p-2  me-2 mb-2'
                style={{
                  color: 'black',
                  backgroundColor: '#e0e0e0',
                  borderRadius: '4px',
                }}
              >
                {tag}
                <TiDelete
                  style={{
                    marginLeft: '5px',
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteTagForRw(tag)}
                />
              </span>
            ))}
          </div>

          <div className='plain-dropdown'>
            <label for=''>Font</label>
            <select
              onChange={(e) =>
                setStoreData({ ...storeData, font: e.target.value })
              }
              value={storeData?.font}
            >
              <option
                value='Helvetica'
                selected={storeData?.font === 'Helvetica' ? true : false}
              >
                Helvetica
              </option>
              <option
                value='Arial'
                selected={storeData?.font === 'Arial' ? true : false}
              >
                Arial
              </option>
              <option
                value='Verdana'
                selected={storeData?.font === 'Verdana' ? true : false}
              >
                Verdana{' '}
              </option>
              <option
                value='Open Sans'
                selected={storeData?.font === 'Open Sans' ? true : false}
              >
                Open Sans{' '}
              </option>
            </select>
          </div>
          <div className='plain-dropdown'>
            <label for=''>Sorting order</label>
            <select
              onChange={(e) =>
                setStoreData({ ...storeData, sort: e.target.value })
              }
              // onChange={(e) => {
              //   handleSort(e.target.value)
              // }}
              value={storeData?.sort}
            >
              <option
                value='name_ascending'
                selected={storeData?.sort === 'name_ascending' ? true : false}
              >
                Alphabetical
              </option>

              <option
                value='thc_ascending'
                selected={storeData?.sort === 'thc_ascending' ? true : false}
              >
                THC Ascending
              </option>
              <option
                value='thc_descending'
                selected={storeData?.sort === 'thc_descending' ? true : false}
              >
                THC Descending
              </option>
            </select>
          </div>

          <div className='plain-input my-3'>
            <label for=''>Footer left</label>
            <br />
            <input
              type='text'
              placeholder='text to display at the bottom left of the screen'
              value={storeData.footer_left}
              onChange={(e) =>
                setStoreData({ ...storeData, footer_left: e.target.value })
              }
              name='footer left'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Footer Center</label>
            <br />
            <input
              type='text'
              placeholder='text to display at the bottom center of the screen'
              value={storeData.footer_center}
              onChange={(e) =>
                setStoreData({ ...storeData, footer_center: e.target.value })
              }
              name='footer center'
            />
          </div>

          <div className='plain-input my-3'>
            <label for=''>Social Link(QR Link)</label>
            <br />
            <input
              type='text'
              placeholder='Please input your address'
              value={storeData.social_link}
              onChange={(e) =>
                setStoreData({ ...storeData, social_link: e.target.value })
              }
              name='social_link'
            />
          </div>
          {
                   storeData?.greenLineApi && 
                   <div className='plain-input my-3'>
                   <label for=''>API Key*</label>
                   <br />
                   <input
                     type='text'
                     placeholder='Please input your api key'
                     value={storeData?.api_key}
                     onChange={handleInput}
                     name='api_key'
                   />
                 </div>

          }

          <div className='plain-input my-3'>
            <label for=''>Company ID*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your Company ID'
              value={storeData.company_id}
              onChange={(e) =>
                setStoreData({ ...storeData, company_id: e.target.value })
              }
              name='company_id'
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Store ID*</label>
            <br />
            <input
              type='text'
              disabled={true}
              placeholder='Please input your store ID'
              value={storeData.short_id}
              onChange={(e) =>
                setStoreData({ ...storeData, store_id: e.target.value })
              }
              name='store_id'
            />
          </div>

          {/* <div className='plain-input my-3'>
            <label for=''>Product API Link*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your product api link'
              value={storeData.link}
              onChange={(e) =>
                setStoreData({ ...storeData, link: e.target.value })
              }
              name='link'
            />
          </div>

          <div className='plain-input my-3'>
            <label htmlFor=''>Inventory API Link*</label>
            <br />
            <input
              type='text'
              placeholder='Please input your inventory api link'
              value={storeData.inventory_link}
              onChange={(e) =>
                setStoreData({ ...storeData, inventory_link: e.target.value })
              }
              name='inventory_link'
            />
          </div> */}

          {/* </form> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className='danger-btn-light'
            onClick={() => {
              handleClose()

              setDeleteModal(true)
            }}
          >
            Delete
          </button>
          <button className='primary-btn-light' onClick={handleClose}>
            Close
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => postStoreData()}
            type='submit'
          >
            Save Changes{' '}
            {editSpinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        <Modal.Body>
          <h4 className='fw-bold text-danger'>Caution!</h4>
          <h5>You are going to delete this store which is irreversible.</h5>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          <button
            className='primary-btn-light'
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => handleDelete()}
            type='submit'
          >
            Delete{' '}
            {deleteSpinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditStoreModal
