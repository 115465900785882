import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import demoImg from '../../../assets/images/demoLogoImg.png'
import { GetScreen } from '../../../constants/api.constants'
import {
  DeleteStoreEnd,
  FileUploadEnd,
  StoreEdit,
} from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import EmojiPicker, {
    EmojiStyle,
    SkinTones,
    Theme,
    Categories,
    EmojiClickData,
    Emoji,
    SuggestionMode,
    SkinTonePickerLocation
  } from "emoji-picker-react";


const AddProductTagModal = ({screenId, productId,show, handleClose, data, loadEmojiData,handleProductTag }) => {
  const history = useHistory()
  const [photoSpinner, setPhotoSpinner] = useState(false)
  const [photoUrl, setPhotoUrl] = useState('')
  // const [uploadURL,setUploadURL] = useState('');
//   const [deleteModal, setDeleteModal] = useState(false)
//   const [deleteSpinner, setDeleteSpinner] = useState(false)
  const [productTag, setProductTag] = useState({
      tag_value: "",
      tag_type: "text"
  })
  const [spinner, setSpinner] = useState(false)
  const [emoji, setEmoji] = useState();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  function onClick(emojiData: EmojiClickData, event: MouseEvent) {
    setSelectedEmoji(emojiData.unified);
    setProductTag({
      tag_value: emojiData.emoji,
      tag_type: 'emoji'
    })
  }
  const handleAddGIFTag = async(e) =>{
    try{
      setPhotoSpinner(true)
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      const config = {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      };
      const response = await axios.post(GetScreen + `/${screenId}` + '/upload_gif/'+`${productId}`, formData,{
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if(response.status == 200){
        setPhotoUrl(response.data.path)
        setProductTag({
          tag_type:'gif',
          tag_value:response.data.path
        })
        Toast('success', 'Tag file Successfully uploaded')
        setPhotoSpinner(false)
      }
      
    }catch(error){
      setPhotoSpinner(false);
      Toast('err', error.response?.data?.msg || 'GIF Upload Failed')
    }
  }
  useEffect(()=>{
    setProductTag({
      tag_type:'text',
      tag_value:''
    })
  },[])

  function handleInput(e) {
    
    setProductTag({
      ...productTag,
      [e.target.name]: e.target.value,
    })
  }

  async function postProductTag(){
    setSpinner(true)
    if (productTag.tag_value === '') {
        Toast('err', 'Please enter tag')
        setSpinner(false)
        return
      }
    try{
    const res = await axios.post(
      GetScreen + `/${screenId}` + '/product_tags/'+`${productId}`,
      productTag,
      {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      }
    )

    if (res.status === 200) {
      // setSpinner(false)
      handleProductTag(productTag.tag_value,productTag.tag_type)
      Toast('success', 'Tag Updated Successfully')
      setSpinner(false)
      handleClose();
    }
    
  } catch (error) {
    setSpinner(false)
    Toast('err', error.response?.data?.msg || 'Try again later!')
  }
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>Add Emoji</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
        <div className='plain-dropdown' 
          style={{width:'80%', marginInline:'auto'}}
        >
            <label for=''>Tag Type : </label>
            <select
              onChange={(e) =>
                setProductTag({ ...productTag, tag_type: e.target.value })
              }
              value={productTag?.tag_type}
            >
              <option
                value='emoji'
                selected={productTag?.tag_type === 'emoji' ? true : false}
              >
                Emoji
              </option>
              <option
                value='gif'
                selected={productTag?.tag_type === 'gif' ? true : false}
              >
                GIF
              </option>
              <option
                value='text'
                selected={productTag?.tag_type === 'text' ? true : false}
              >
                Text
              </option>
              {/* <option
                value='Open Sans'
                selected={storeData?.font === 'Open Sans' ? true : false}
              >
                Open Sans{' '}
              </option> */}
            </select>
          
        </div>
        {productTag.tag_type === 'emoji'?(
          <div style={{marginInline:'auto',width:'80%'}}>
          <div style={{height:'10%', marginBlock:'3%'}}>
                
              {selectedEmoji ? (
              <h4 >Your selected Emoji is : &nbsp; &nbsp;
              <Emoji
                  unified={selectedEmoji}
                  emojiStyle={EmojiStyle.APPLE}
                  size={22}
  
              />
              </h4>
              ) : (
                <h4 >You haven't selected any emoji yet</h4>
              )}
              
          </div>
          <EmojiPicker
          onEmojiClick={onClick}
          autoFocusSearch={false}
          // theme={Theme.AUTO}
          // searchDisabled
          // skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
          // height={350}
          width="100%"
          // emojiVersion="0.6"
          // lazyLoadEmojis={true}
          // previewConfig={{
          //   defaultCaption: "Pick one!",
          //   defaultEmoji: "1f92a" // 🤪
          // }}
          // suggestedEmojisMode={SuggestionMode.RECENT}
          // skinTonesDisabled
          // searchPlaceHolder="Filter"
          // defaultSkinTone={SkinTones.MEDIUM}
          // emojiStyle={EmojiStyle.NATIVE}
        />
        </div>
        ):productTag.tag_type === 'gif'?(
          <div className='d-flex justify-content-start align-items-end'
            style={{width:'80%', marginInline:'auto', marginBlock:'5%'}}
          >
            <img
                src={photoUrl || demoImg}
                alt=''
                height='100'
                width='200'
                className='me-4'
                />
            <Form.Group className='' controlId='formBasicEmail'>
                <Form.Label>
                {/* {data?.type}* */}
                {photoSpinner && (
                    <Spinner className='ms-1' animation='border' size='sm' />
                )}
                </Form.Label>
                <Form.Control type='file' onChange={(e) => handleAddGIFTag(e)} />
            </Form.Group>            
        </div>
        ):(<div style={{width:'80%', marginInline:'auto'}}>
            <div className='plain-input my-3'>
            <label for=''>Text</label>
            <br />
            <input
              type='text'
              placeholder='Please input the text of your tag'
              value={productTag.tag_value}
              onChange={handleInput}
              name='tag_value'
            />
          </div>
          </div>)}      
        
      </div>
          
        </Modal.Body>
        <Modal.Footer>
          <button className='primary-btn-light' onClick={handleClose}>
            Close
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => postProductTag()}
            type='submit'
          >
            Save Changes{' '}
            {spinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddProductTagModal
